.resources-list-header-gallery {
  opacity: 0;
  transition: opacity 1s;
}

.resources-list-header-gallery.show {
  opacity: 1;
}

.resources-list-header-gallery {
  height: 20rem;
  padding-top: 10rem;
  background-size: cover;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
  transition: 1s;
}

@media (max-width: 480px) {
  .resources-list-header-gallery {
    display: block;
  }
}

.resources-list-header-subtitle {
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
  text-align: center;
  color: #ddd;
  width: 50%;
  margin: auto;
  min-width: 30rem;
}