.events-gallery-header {
  padding-top: 20vh;
  padding-bottom: 15rem;
  height: 20rem;
  padding-top: 10rem;
  background-size: cover;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.25);
}

.events-gallery-header h1 {
  font-size: 3rem;
  font-weight: 800;
  font-family: Poppins, sans-serif;
  text-align: center;
  color: #ddd;
  margin: 0 20px;
  min-width: 30rem;
  padding-bottom: 20px;
}

.events-gallery-header p {
  font-family: Poppins, sans-serif;
  font-size: 1.2rem;
  text-align: center;
  color: #ddd;
  width: 70%;
  margin: auto;
  min-width: 30rem;
}

.image-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100vw;
  padding: 0 2vw;
  justify-content: center;
  align-items: center;
  padding-bottom: 20vh;
}

.image-grid-item img {
  height: 24vw;
  width: 30vw;
  border-radius: 10px;
  object-fit: cover;
}

.image-grid-item {
  justify-self: center;
  align-self: center;
  width: 30vw;
  height: 24vw;
}

@media (max-width: 480px) {
  .image-grid-item img {
    height: 60vw;
    width: 80vw;
  }

  .image-grid-item {
    width: 80vw;
    height: 30vw;
    margin: 10vh 0;
  }

}